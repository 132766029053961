/* eslint-disable max-len */
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import notification from 'antd/lib/notification';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';
import Dropdown from 'antd/lib/dropdown';
import { SettingOutlined, UpOutlined } from '@ant-design/icons';
import * as THREE from 'three';
import { create, all } from 'mathjs';
import CVATTooltop from 'components/common/cvat-tooltip';
import { CombinedState } from 'reducers';
import ContextImageSelector from './context-image-selector';
import colorAttributes from '../../../../../../../cvat-canvas3d/src/typescript/colors';
import ImageSetupsContent from '../canvas2d/image-setups-content';

const config = { };
const math = create(all, config);
interface Props {
    offset: number[];
}

function ContextImage(props: Props): JSX.Element {
    const { offset } = props;
    const defaultFrameOffset = (offset[0] || 0);
    const defaultContextImageOffset = (offset[1] || 0);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const job = useSelector((state: CombinedState) => state.annotation.job.instance);
    const { number: frame, relatedFiles } = useSelector((state: CombinedState) => state.annotation.player.frame);
    const { states: annotationStates, activatedStateID } = useSelector((state: CombinedState) => state.annotation.annotations);
    const { dimensionUpdatedObjectState: updatedState, perspectiveViewPoints } = useSelector((state: CombinedState) => state.annotation);
    const {
        opacity, selectedOpacity, colorBy, colorAttribute,
    } = useSelector((state: CombinedState) => state.settings.shapes);
    const { brightnessLevel, contrastLevel, saturationLevel } = useSelector((state:CombinedState) => state.settings.player);
    const [statePoints, setPoints] = useState(annotationStates); // anurag
    const frameIndex = frame + defaultFrameOffset;
    const [refreshed, setRefreshed] = useState(false);

    const [contextImageData, setContextImageData] = useState<Record<string, ImageBitmap>>({});

    const [fetching, setFetching] = useState<boolean>(false);
    const [contextImageOffset, setContextImageOffset] = useState<number>(
        Math.min(defaultContextImageOffset, relatedFiles),
    );

    // when dimensions changes
    useEffect(() => {
        if (updatedState.length > 0) {
            setPoints(updatedState);
        }
    }, [updatedState]);
    // Listens for everychange in main state
    useEffect(() => {
        setPoints(annotationStates);
    }, [annotationStates]);

    const projectionData = useRef([]);
    const annotationStateData = useRef([]);
    const annoActivatedStateID = useRef();
    const annoOpacity = useRef();
    const selectedShapeOpacity = useRef();
    const canvasBrightnessLevel = useRef();
    const canvasContrastLevel = useRef();
    const canvasSaturationLevel = useRef();
    const lineWidthZoom = useRef(4);
    const line = useRef(6);
    const counterFactor = useRef({ zoomLevel: 1, x: 0, y: 0 });
    const [projections, setProjections] = useState<Array>([]);
    const [perspectiveViewPointState, setPerspectiveViewPoints] = useState(perspectiveViewPoints);
    const [hasError, setHasError] = useState<boolean>(false);
    const [showSelector, setShowSelector] = useState<boolean>(false);
    const labelcolor = useRef();
    const labelAttribute = useRef();
    useEffect(() => {
        let unmounted = false;

        const promise = job.frames.contextImage(frameIndex);
        setFetching(true);
        promise.then((imageBitmaps: Record<string, ImageBitmap>) => {
            if (!unmounted) {
                lineWidthZoom.current = 1;

                setContextImageData(imageBitmaps);
                if (!localStorage.getItem('zoom') || parseFloat(localStorage.getItem('zoom')).length === 0) {
                    window.localStorage.setItem('zoom', JSON.stringify(counterFactor.current));
                }
                counterFactor.current.zoomLevel = JSON.parse(localStorage.getItem('zoom')).zoomLevel;
                annoActivatedStateID.current = activatedStateID;
                annoOpacity.current = opacity;
                labelcolor.current = colorBy;
                labelAttribute.current = colorAttribute;
                selectedShapeOpacity.current = selectedOpacity;
                line.current = counterFactor.current.zoomLevel;
            }
        }).catch((error: any) => {
            if (!unmounted) {
                setHasError(true);
                notification.error({
                    message: `Could not fetch context images. Frame: ${frameIndex}`,
                    description: error.toString(),
                });
            }
        }).finally(() => {
            if (!unmounted) {
                setFetching(false);
            }
        });

        return () => {
            setContextImageData({});

            unmounted = true;
        };
    }, [frameIndex]);

    function trackTransforms(ctx) {
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        let xform = svg.createSVGMatrix();

        const savedTransforms = [];
        const { save } = ctx;
        ctx.save = () => {
            savedTransforms.push(xform.translate(0, 0));
            return save.call(ctx);
        };

        const { restore } = ctx;
        ctx.restore = () => {
            xform = savedTransforms.pop();
            return restore.call(ctx);
        };

        const { scale } = ctx;
        ctx.scale = (sx, sy) => {
            xform = xform.scaleNonUniform(sx, sy);
            return scale.call(ctx, sx, sy);
        };

        const { translate } = ctx;
        ctx.translate = function (dx, dy) {
            xform = xform.translate(dx, dy);
            return translate.call(ctx, dx, dy);
        };
        ctx.transformedPoint = (x, y) => {
            const originalPoint = new DOMPoint(x, y);
            return ctx.getTransform().invertSelf().transformPoint(originalPoint);
        };
    }

    useEffect(() => {
        const homegenous_coord_adcam_to_innoviz = () => {
            const transform = JSON.parse(localStorage.getItem('transforms'));
            const translation = new THREE.Vector3(-0.376, 0.065, 2.998);
            const rotation = new THREE.Quaternion(0.6537924043746445, 0.6538050217638328, -0.2739354605292423, -0.2646768765174759);
            const scale = new THREE.Vector3(1, 1, 1);
            return new THREE.Matrix4().compose(translation, rotation, scale).toArray();
        };

        const matrixLidarToCamera = homegenous_coord_adcam_to_innoviz();
        const matrixLidarToCamera11 = [
            [matrixLidarToCamera[0], matrixLidarToCamera[4], matrixLidarToCamera[8], matrixLidarToCamera[12]],
            [matrixLidarToCamera[1], matrixLidarToCamera[5], matrixLidarToCamera[9], matrixLidarToCamera[13]],
            [matrixLidarToCamera[2], matrixLidarToCamera[6], matrixLidarToCamera[10], matrixLidarToCamera[14]],
            [matrixLidarToCamera[3], matrixLidarToCamera[7], matrixLidarToCamera[11], matrixLidarToCamera[15]],
        ];

        const transposed_matrix = statePoints.length > 0 ? statePoints?.map((annotation) => {
            const dimensions = annotation.objectType === 'shape' ? annotation.pointsRealTime : annotation.points;
            const l = dimensions[7];
            const w = dimensions[6];
            const h = dimensions[8];

            let xPos; let yPos; let zPos; let c; let
                s;

            const Ry = dimensions[5];
            const Rx = dimensions[4];
            const Rz = dimensions[3];

            xPos = dimensions[0]; // lat
            yPos = dimensions[1]; // long
            zPos = dimensions[2]; // vertical

            const cornerPoints: any[] = [];

            cornerPoints.push(new THREE.Vector3(xPos - l / 2, yPos - w / 2, zPos + h / 2)); // 0
            cornerPoints.push(new THREE.Vector3(xPos + l / 2, yPos - w / 2, zPos + h / 2)); // 1
            cornerPoints.push(new THREE.Vector3(xPos + l / 2, yPos + w / 2, zPos + h / 2));
            cornerPoints.push(new THREE.Vector3(xPos - l / 2, yPos + w / 2, zPos + h / 2));
            cornerPoints.push(new THREE.Vector3(xPos - l / 2, yPos - w / 2, zPos - h / 2));
            cornerPoints.push(new THREE.Vector3(xPos + l / 2, yPos - w / 2, zPos - h / 2)); // 5
            cornerPoints.push(new THREE.Vector3(xPos + l / 2, yPos + w / 2, zPos - h / 2)); // 6
            cornerPoints.push(new THREE.Vector3(xPos - l / 2, yPos + w / 2, zPos - h / 2)); // 7

            // For ArrowPoints

            cornerPoints.push(new THREE.Vector3(xPos + l / 2, yPos - w / 4, zPos - h / 2)); // 1arrow
            cornerPoints.push(new THREE.Vector3(xPos + l / 2, yPos + w / 4, zPos - h / 2)); // 2arrow
            cornerPoints.push(new THREE.Vector3(xPos + l / 2 + 0.9, yPos + w / 4, zPos - h / 2)); // 3arrow
            cornerPoints.push(new THREE.Vector3(xPos + l / 2 + 0.9, yPos + w / 2, zPos - h / 2)); // 4arrow
            cornerPoints.push(new THREE.Vector3(xPos + l / 2 + 1.5, yPos, zPos - h / 2)); // 5arrow
            cornerPoints.push(new THREE.Vector3(xPos + l / 2 + 0.9, yPos - w / 2, zPos - h / 2)); // 6arrow
            cornerPoints.push(new THREE.Vector3(xPos + l / 2 + 0.9, yPos - w / 4, zPos - h / 2)); // 7arrow
            // TOP VIEW ROTATION(Z-AXIS Rotation)
            c = Math.cos(Ry);
            s = Math.sin(Ry);

            for (let index = cornerPoints.length - 1; index > -1; --index) {
                const p = cornerPoints[index];
                const y = (p.y - yPos) * s + (p.x - xPos) * c;
                const x = (p.y - yPos) * c - (p.x - xPos) * s;
                p.x = x + xPos;
                p.y = y + yPos;
            }

            // SIDE VIEW ROTATION(X-AXIS Rotation)
            c = Math.cos(Rx);
            s = Math.sin(Rx);

            for (let index = cornerPoints.length - 1; index > -1; --index) {
                const p = cornerPoints[index];
                const x = (p.z - zPos) * s + (p.x - xPos) * c;
                const z = (p.z - zPos) * c - (p.x - xPos) * s;
                p.x = x + xPos;
                p.z = z + zPos;
            }
            // FRONT VIEW ROTATION(Y-AXIS Rotation)
            c = Math.cos(Rz);
            s = Math.sin(Rz);

            for (let index = cornerPoints.length - 1; index > -1; --index) {
                const p = cornerPoints[index];
                const y = (p.y - yPos) * c - (p.z - zPos) * s;
                const z = (p.y - yPos) * s + (p.z - zPos) * c;
                p.y = y + yPos;
                p.z = z + zPos;
            }

            // Create a new 3x4 matrix
            // 3X4 X 4X4 = 3X4

            // 3X4 X 4X1 = 3X1
            // 3X4

            // const K = [
            //     [258.3, 0.0, 635.9, 0.0],
            //     [0.0, 256.6, 384.5, 0.0],
            //     [0.0, 0.0, 1.0, 0.0],
            // ];

            // const D = [0.2165, -0.002033, 0.01037, -0.002643];

            // const projectionMat = math.multiply(K, D);

            // console.log(projectionMat);

            const projectionMatrix = [
                // [2409.758056640625, 0.0, 1912.5518798828125, 0.0],
                // [0.0, 2409.758056640625, 1464.886474609375, 0.0],
                // [0.0, 0.0, 1.0, 0.0],
                [258.3, 0.0, 635.9, 0.0],
                [0, 256.6, 384.5, 0.0],
                [0, 0, 1.0, 0],
            ];

            // const mat = [
            //     [
            //         0.8528174069844361,
            //         -0.021516433936611047,
            //         -0.5217657648935912,
            //         -0.38792884291988383,
            //     ],
            //     [
            //         0.02191596475894842,
            //         0.999745200679951,
            //         -0.005405941739317996,
            //         0.0877935200601387,
            //     ],
            //     [
            //         0.5217491359197706,
            //         -0.0068247188993996065,
            //         0.8530716630969035,
            //         3.199583071403179,
            //     ],
            //     [
            //         0,
            //         0,
            //         0,
            //         1,
            //     ],
            // ];
            const mulMat = math.inv(matrixLidarToCamera11);

            const lidar_to_pixel = math.multiply(projectionMatrix, mulMat);
            const pointsCamera = cornerPoints.map((point) => math.multiply(lidar_to_pixel, [point.x, point.y, point.z, 1]));

            // Convert points from homogeneous to pixel coordinates

            const pointsImage = pointsCamera.map((point) => {
                if (point[2] > 0) {
                    // add only points that are in front of camera
                    return [point[0] / point[2], point[1] / point[2]];
                }
                // do not draw bounding box if it is too close too camera or behind
                return [];
            });
            return pointsImage;
        }) : [];
        projectionData.current = transposed_matrix;
        annotationStateData.current = statePoints;
        setProjections(transposed_matrix);
    }, [statePoints]);

    useEffect(() => {
        // console.log('perspectiveViewPointState', perspectiveViewPointState);
        if (perspectiveViewPoints) {
            const homegenous_coord_adcam_to_innoviz = () => {
                const transform = JSON.parse(localStorage.getItem('transforms'));
                const translation = new THREE.Vector3(-0.376, 0.065, 2.998);
                const rotation = new THREE.Quaternion(0.6537924043746445, 0.6538050217638328, -0.2739354605292423, -0.2646768765174759);
                const scale = new THREE.Vector3(1, 1, 1);
                return new THREE.Matrix4().compose(translation, rotation, scale).toArray();
            };

            const matrixLidarToCamera = homegenous_coord_adcam_to_innoviz();
            const matrixLidarToCamera11 = [
                [matrixLidarToCamera[0], matrixLidarToCamera[4], matrixLidarToCamera[8], matrixLidarToCamera[12]],
                [matrixLidarToCamera[1], matrixLidarToCamera[5], matrixLidarToCamera[9], matrixLidarToCamera[13]],
                [matrixLidarToCamera[2], matrixLidarToCamera[6], matrixLidarToCamera[10], matrixLidarToCamera[14]],
                [matrixLidarToCamera[3], matrixLidarToCamera[7], matrixLidarToCamera[11], matrixLidarToCamera[15]],
            ];

            // Create a new 3x4 matrix
            const projectionMatrix = [
                // [2409.758056640625, 0.0, 1912.5518798828125, 0.0],
                // [0.0, 2409.758056640625, 1464.886474609375, 0.0],
                // [0.0, 0.0, 1.0, 0.0],
                [258.3, 0.0, 635.9, 0.0],
                [0, 256.6, 384.5, 0.0],
                [0, 0, 1.0, 0],
            ];
            const mulMat = math.inv(matrixLidarToCamera11);

            const lidar_to_pixel = math.multiply(projectionMatrix, mulMat);

            const pointsCamera = math.multiply(lidar_to_pixel, [perspectiveViewPoints.x, perspectiveViewPoints.y, perspectiveViewPoints.z, 1]);
            // console.log('pointsCamera', pointsCamera);
            // Convert points from homogeneous to pixel coordinates

            const pointsImage = pointsCamera[2] > 0 ? [pointsCamera[0] / pointsCamera[2], pointsCamera[1] / pointsCamera[2]] : [];
            // console.log('pointsImage', pointsImage);
            // projectionData.current = transposed_matrix;
            // annotationStateData.current = statePoints ;
            setPerspectiveViewPoints(pointsImage);
        }
    }, [perspectiveViewPoints]);

    // code by raju
    const getColor = (state:any) => {
        let color = '';
        const staticAttributesColor = colorAttributes.filter((attr) => attr.name === labelAttribute.current).map((attr) => attr);
        let newObjProperties: any;
        const attributeFiltername = state.label.attributes.filter((elem) => staticAttributesColor.some((ele) => ele.name === elem.name));
        const attributeListnamewithid = [];
        attributeListnamewithid.push(state?.attributes);
        attributeListnamewithid.filter((attribute) => Object.keys(attribute).map((item) => {
            attributeFiltername.some((ele) => {
                if (ele.id == item) {
                    newObjProperties = attribute[item];
                }
            });
        }));
        const staticAttributeValues = staticAttributesColor.map((el) => el.values);
        staticAttributeValues.map((attributeVal) => Object.keys(attributeVal).map((item) => Object.entries(attributeVal[item]).map(([key, value]) => {
            if (key === newObjProperties) {
                color = value;
            }
        })));
        return color as string || state.label.color as string;
    };

    const drawImageOnCanvas = () => {
        const canvas = canvasRef.current;
        const context = canvasRef.current.getContext('2d');
        if (context) {
            const sortedKeys = Object.keys(contextImageData).sort();
            const key = sortedKeys[contextImageOffset];
            const image = contextImageData[key];

            canvasRef.current.style.filter = `brightness(${canvasBrightnessLevel.current}%)contrast(${canvasContrastLevel.current}%)saturate(${canvasSaturationLevel.current}%)`;

            const p1 = context.transformedPoint(0, 0);

            const p2 = context.transformedPoint(canvas.width, canvas.height);
            context.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
            context.imageSmoothingEnabled = false;

            context.drawImage(image, 0, 0);

            const edges = [[0, 1],
                [1, 2],
                [2, 3],
                [3, 0], // down face
                [4, 5],
                [5, 6],
                [6, 7],
                [7, 4],
                [0, 4],
                [4, 5],
                [5, 1],
                [1, 0], // 1
                [1, 5],
                [5, 6],
                [6, 2],
                [2, 1], // 2
                [2, 6],
                [6, 7],
                [7, 3],
                [3, 2], // 3
                [7, 4],
                [4, 0],
                [0, 3],
                [3, 7],
            ];
            //  console.log('perspectiveViewPointState', perspectiveViewPointState);
            if (perspectiveViewPointState) {
                context.beginPath();
                context.arc(perspectiveViewPointState[0], perspectiveViewPointState[1], 20 / line.current, 0, (2 * Math.PI));
                context.fillStyle = 'rgba(250,0,0,0.3)';
                context.lineWidth = 10;
                context.strokeStyle = 'rgba(250,255,0,1)';
                context.stroke();
                context.fill();
            }
            if (projectionData.current && projectionData.current.length > 0) {
                projectionData.current.forEach((projection: any, index: number) => {
                    if (!annotationStateData.current[index].hidden && !annotationStateData.current[index].outside) {
                        // eslint-disable-next-line no-nested-ternary

                        const colorname = labelcolor.current === 'Label' ? statePoints[index]?.label.color : labelcolor.current === 'Attributes' ? getColor(annotationStateData.current[index]) : statePoints[index]?.color;
                        function convertHex(hexCode: string, opacity = 0.5) {
                            if (!hexCode) {
                                return;
                            }
                            let hex = hexCode.replace('#', '');
                            if (hex.length === 3) {
                                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                            }

                            const r = parseInt(hex.substring(0, 2), 16);
                            const g = parseInt(hex.substring(2, 4), 16);
                            const b = parseInt(hex.substring(4, 6), 16);

                            if (opacity > 0 && opacity <= 100) {
                                opacity /= 100;
                            }

                            return `rgba(${r},${g},${b},${opacity})`;
                        }

                        const arrowPoints = projection.slice(8);
                        projection = projection.slice(0, 8);

                        context.fillStyle = annotationStateData.current[index]?.clientID === annoActivatedStateID.current ? `rgba(0,255,0,${selectedShapeOpacity.current / 100})` : convertHex(colorname, annoOpacity.current);
                        context.strokeStyle = annotationStateData.current[index]?.clientID === annoActivatedStateID.current ? '#00ff00' : colorname;
                        context.beginPath();
                        context.moveTo(projection[edges[0][0]][0], projection[edges[0][0]][1]);
                        for (let i = 0; i < edges.length; i++) {
                            if (i % 4 === 0) {
                                context.closePath();
                                context.fill();
                                context.lineWidth = 5.5 / line.current;

                                context.stroke();
                                context.beginPath();
                                context.moveTo(projection[edges[i][0]][0], projection[edges[i][0]][1]);
                            }
                            context.lineTo(projection[edges[i][1]][0], projection[edges[i][1]][1]); // 1
                        }
                        context.fill();
                        context.closePath();
                        context.beginPath();
                        context.lineWidth = 5.5 / line.current;
                        context.moveTo(arrowPoints[0][0], arrowPoints[0][1]);
                        for (let i = arrowPoints.length - 1; i > 0; --i) {
                            context.lineTo(arrowPoints[i][0], arrowPoints[i][1]);
                        }
                        context.fillStyle =
                            annotationStateData.current[index]?.clientID === annoActivatedStateID.current ?
                                `rgba(255,255,255,${selectedShapeOpacity.current / 100})` :
                                convertHex(colorname, annoOpacity.current);
                        context.strokeStyle = 'rgba(255,255,255,1)';
                        context.stroke();
                        context.closePath();
                        context.fill();
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (canvasRef.current && canvasRef.current.getContext('2d') && typeof (canvasRef.current.getContext('2d').transformedPoint) === 'function') {
            annoActivatedStateID.current = activatedStateID;
            annoOpacity.current = opacity;
            labelcolor.current = colorBy;
            labelAttribute.current = colorAttribute;
            selectedShapeOpacity.current = selectedOpacity;
            canvasBrightnessLevel.current = brightnessLevel;
            canvasContrastLevel.current = contrastLevel;
            canvasSaturationLevel.current = saturationLevel;
            drawImageOnCanvas();
        }
    }, [projections, activatedStateID, opacity, selectedOpacity, colorBy, colorAttribute, perspectiveViewPointState, brightnessLevel, contrastLevel, saturationLevel]);

    useEffect(() => {
        if (canvasRef.current) {
            const sortedKeys = Object.keys(contextImageData).sort();
            const key = sortedKeys[contextImageOffset];
            const image = contextImageData[key];

            const context = canvasRef.current.getContext('2d');
            if (context && image) {
                canvasRef.current.width = image.width;
                canvasRef.current.height = image.height;
                annoOpacity.current = opacity;
                labelcolor.current = colorBy;
                labelAttribute.current = colorAttribute;
                selectedShapeOpacity.current = selectedOpacity;
                trackTransforms(context);
                let zoom: number;

                // annoActivatedStateID.current = activatedStateID;

                // drawImageOnCanvas();

                let data;
                if (localStorage.getItem('zoom')) {
                    data = localStorage.getItem('zoom');
                    data = JSON.parse(data);
                }

                // after clicking next, scale and translate code
                context.translate(data.x, data.y);
                context.scale(data.zoomLevel, data.zoomLevel);
                context.translate(-data.x, -data.y);
                drawImageOnCanvas();

                let lastX = canvasRef.current.width / 2;
                let lastY = canvasRef.current.height / 2;
                let x; let
                    y;
                let dragStart: any; let
                    dragged: any;
                const scaleFactor = 1.1;

                function doubleClick(evt) {
                    const zoom = { zoomLevel: 1, x: 0, y: 0 };
                    localStorage.setItem('zoom', JSON.stringify(zoom));
                    lineWidthZoom.current = 1;
                    setRefreshed(!refreshed);
                    counterFactor.current.zoomLevel = JSON.parse(localStorage.getItem('zoom')).zoomLevel;
                    drawImageOnCanvas();
                    line.current = 1;
                }

                function mouseDown(evt) {
                    lastX = evt.offsetX || evt.pageX - canvasRef.current.offsetLeft;
                    lastY = evt.offsetY || evt.pageY - canvasRef.current.offsetTop;
                    x = evt.clientX - canvasRef.current.width / 2;
                    y = evt.clientY - canvasRef.current.width / 2;
                    dragStart = context.transformedPoint(lastX, lastY);
                    dragged = false;
                    evt.preventDefault();
                    evt.stopPropagation();
                }
                function contextmenu(evt) {
                    evt.preventDefault();
                    evt.stopPropagation();
                }

                function mouseMove(evt) {
                    lastX = evt.offsetX || evt.pageX - canvasRef.current.offsetLeft;
                    lastY = evt.offsetY || evt.pageY - canvasRef.current.offsetTop;
                    dragged = true;
                    if (dragStart) {
                        const pt = context.transformedPoint(lastX, lastY);
                        context.translate(pt.x - dragStart.x, pt.y - dragStart.y);

                        drawImageOnCanvas();
                    }
                }
                function mouseUp(evt) {
                    dragStart = null;
                    dragStart = false;
                }
                function zoomHandler(event) {
                    event.preventDefault();
                    const rect = document.getElementById('2d_canvas');
                    lastX = event.offsetX || event.pageX - canvasRef.current.offsetLeft;
                    lastY = event.offsetY || event.pageY - canvasRef.current.offsetTop;
                    const updateX = lastX * (image.width / rect.offsetWidth);
                    const updateY = lastY * (image.height / rect.offsetHeight);

                    const currentTransformedCursor = context.transformedPoint(updateX, updateY);
                    zoom = event.deltaY > 0 ? -1 : 1;
                    lineWidthZoom.current = zoom > 0 ? lineWidthZoom.current + 1 : lineWidthZoom.current - 1;
                    if (lineWidthZoom.current > 60) return;

                    context.translate(currentTransformedCursor.x, currentTransformedCursor.y);
                    const factor = Math.pow(scaleFactor, zoom);
                    if (currentTransformedCursor.x + currentTransformedCursor.y <= image.height + image.width && currentTransformedCursor.x > 0 && currentTransformedCursor.y > 0) {
                        context.scale(factor, factor);
                        counterFactor.current.zoomLevel *= factor;
                        counterFactor.current.x = currentTransformedCursor.x;
                        counterFactor.current.y = currentTransformedCursor.y;
                    }
                    line.current = counterFactor.current.zoomLevel;

                    if ((5.5 / line.current) >= 6) {
                        context.lineWidth = 6;
                    }

                    localStorage.setItem('zoom', JSON.stringify(counterFactor.current));
                    context.translate(-currentTransformedCursor.x, -currentTransformedCursor.y);
                    // Redraws the image after the scaling
                    drawImageOnCanvas();
                    // Stops the whole page from scrolling
                }
                canvasRef.current.addEventListener('wheel', zoomHandler, { passive: false });
                canvasRef.current.addEventListener('mousemove', mouseMove);
                canvasRef.current.addEventListener('mousedown', mouseDown);
                canvasRef.current.addEventListener('mouseup', mouseUp);
                canvasRef.current.addEventListener('contextmenu', contextmenu);
                canvasRef.current.addEventListener('dblclick', doubleClick);
                return () => {
                    if (canvasRef.current) {
                        canvasRef.current?.removeEventListener('wheel', zoomHandler);
                        canvasRef.current?.removeEventListener('mousemove', mouseMove);
                        canvasRef.current?.removeEventListener('mousedown', mouseDown);
                        canvasRef.current?.removeEventListener('mouseup', mouseUp);
                        canvasRef.current.removeEventListener('contextmenu', contextmenu);
                        canvasRef.current.removeEventListener('dblclick', doubleClick);
                    }
                };
            }
        }
    }, [contextImageData, contextImageOffset, canvasRef, refreshed]);
    const contextImageName = Object.keys(contextImageData).sort()[contextImageOffset];
    return (
        <div className='cvat-context-image-wrapper'>
            <div className='cvat-context-image-header'>
                { relatedFiles > 1 && (
                    <SettingOutlined
                        className='cvat-context-image-setup-button'
                        onClick={() => {
                            setShowSelector(true);
                        }}
                    />
                )}
                <div className='cvat-context-image-title'>
                    <CVATTooltop title={contextImageName}>
                        <Text>{contextImageName}</Text>
                    </CVATTooltop>
                </div>
            </div>
            { (hasError ||
                (!fetching && contextImageOffset >= Object.keys(contextImageData).length)) && <Text> No data </Text>}
            { fetching && <Spin size='small' /> }
            {
                contextImageOffset < Object.keys(contextImageData).length &&
                <canvas id='2d_canvas' ref={canvasRef} />
            }
            { showSelector && (
                <ContextImageSelector
                    images={contextImageData}
                    offset={contextImageOffset}
                    onChangeOffset={(newContextImageOffset: number) => {
                        setContextImageOffset(newContextImageOffset);
                    }}
                    onClose={() => {
                        setShowSelector(false);
                    }}
                />
            )}
            <div>
                <Dropdown trigger={['click']} placement='topCenter' overlay={<ImageSetupsContent />}>
                    <UpOutlined className='cvat-canvas-image-setups-trigger' />
                </Dropdown>
            </div>
        </div>
    );
}

ContextImage.PropType = {
    offset: PropTypes.arrayOf(PropTypes.number),
};

export default React.memo(ContextImage);
